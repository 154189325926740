import React from "react";
import Navbar from "../Navbar/Navbar";

function Header() {
	/*const [isExpanded, toggleExpansion] = useState(false);
	const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
	      site {
	        siteMetadata {
	          title
	        }
	      }
	    }
	  `);*/
	return (
		<header className="">
			<Navbar/>
		</header>
	);
}

export default Header;
