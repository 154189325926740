import React, {Component} from 'react';
import logo from "../../images/logo-white-1x.png";
import {StyledNavbar} from "./styles";
import {Link} from "gatsby";

class Navbar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isExpanded: false,
			affix: false,
		}
	}

	componentDidMount(){
		window.addEventListener('scroll', this.handleScroll);
	}
	componentWillUnmount(){
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll = (event) => {
		let mainNav = document.querySelector("#mainNav");
		let rect = mainNav.getBoundingClientRect();
		let offset = {
			top: rect.top + window.scrollY,
			left: rect.left + window.scrollX,
		};
		if (offset.top > 20) {
			mainNav.classList.add("affix");
		} else {
			mainNav.classList.remove("affix");
		}
	}
	render() {
		const {isExpanded} = this.state;
		return (
			<StyledNavbar id="mainNav" className="fixed block top-0 left-0 right-0 bg-transparent flex flex-row justify-start z-50 p-1 transition px-4 py-3" isExpanded={isExpanded}>
				<div className="container sm:flex sm:justify-between items-center  mx-auto">
					<div className="flex justify-between items-center">
						<Link className="navbar-brand" to="/">
							<img src={logo} width="140" alt="logo" className="img-fluid" />
						</Link>
						<button className="sm:hidden" onClick={() => this.setState({isExpanded: !isExpanded})}>
							<svg viewBox="0 0 100 80" width="40" height="40" fill="#fff">
								<rect width="100" height="10"></rect>
								<rect y="30" width="100" height="10"></rect>
								<rect y="60" width="100" height="10"></rect>
							</svg>
						</button>
					</div>
					<div className={`${isExpanded ? 'block' : 'hidden'} sm:block transition`}>
						<ul className="sm:flex sm:flex-row ml-auto text-white">
							<li className="py-2 sm:py-4 ">
								<Link to="/" className="px-2 ml-5 opacity-75 hover:opacity-100">Home</Link>
							</li>
							<li className="py-2 sm:py-4">
								<Link to="/about" className="px-2 ml-5 opacity-75 hover:opacity-100">About Us</Link>
							</li>
							<li className="py-2 sm:py-4 ">
								<Link to="/services" className="px-2 ml-5 opacity-75 hover:opacity-100">Our Services</Link>
							</li>
							<li className="py-2 sm:py-4">
								<Link to="/contact" className="px-2 ml-5 opacity-75 hover:opacity-100">Contact Us</Link>
							</li>
						</ul>
					</div>
				</div>
			</StyledNavbar>
		);
	}
}

export default Navbar;
