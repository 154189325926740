import PropTypes from "prop-types";
import React from "react";

import Header from "./Header/header";

function Layout({ children }) {
	return (

		<>
			<Header />

			<main className="">
				{children}
			</main>
			<footer className="bg-primary-blue p-6 text-white">
				<div className="container mx-auto">
					{/*<div>
						<h4 className="text-white">Quick Links</h4>
					</div>*/}

					<div className="flex">
						<p>Copyrights © 2020. All rights reserved</p>
					</div>
				</div>
			</footer>

		</>
	);
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
